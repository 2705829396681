import * as storage from "localforage";

export const getUserCompany = async (options) => {
  const companyId = await storage.getItem("companyId");

  return companyId;
};

export const datePickerMaxDate = new Date().setFullYear(
  new Date().getFullYear() - 18
);

export const isrType = [
  {
    label: 'Sujetos',
    value: 'Sujetos',
  },
  {
    label: 'Exentos',
    value: 'Exentos',
  },
  {
    label: 'Servicios',
    value: 'Servicios',
  },
]

export const statusUser = [
  {
    label: "Inactivo",
    value: 0,
    colorClass: "border-gray-500 text-gray-500",
  },
  {
    label: "Activo",
    value: 1,
    colorClass: "border-green-400 text-green-400",
  },
];

export const statusPersonnelActions = [
  {
    label: "Creada",
    value: 0,
  },
  {
    label: "Aplicada",
    value: 1,
  },
  {
    label: "Anulada",
    value: 2,
  },
];

export const documentType = [
  {
    label: 'DUI',
    value: 'DUI',
  },
  {
    label: 'Carnet de residencia',
    value: 'Carnet de residencia',
  },
  {
    label: 'Pasaporte',
    value: 'Pasaporte',
  },
]

export const gender = [
  {
    label: 'Masculino',
    value: 'Masculino',
  },
  {
    label: 'Femenino',
    value: 'Femenino',
  },
]

export const maritalStatus = [
  {
    label: 'Soltero(a)',
    value: 'Soltero(a)',
  },
  {
    label: 'Casado(a)',
    value: 'Casado(a)',
  },
  {
    label: 'Divorciado(a)',
    value: 'Divorciado(a)',
  },
  {
    label: 'Viudo(a)',
    value: 'Viudo(a)',
  },
  {
    label: 'Unión Libre(a)',
    value: 'Unión Libre(a)',
  },
]

export const allStatuses = [
  {
    label: "Creado",
    value: 0,
  },
  {
    label: "Activo",
    value: 1,
  },
  {
    label: "Inactivo",
    value: 2,
  },
];

export const statusesSearch = [
  {
    label: "Todos",
    value: -1,
  },
  {
    label: "Activo",
    value: 1,
  },
  {
    label: "Inactivo",
    value: 2,
  },
];

export const statuses = [
  {
    label: "Inactivo",
    value: 0,
    colorClass: "border-gray-500 text-gray-500",
  },
  {
    label: "Activo",
    value: 1,
    colorClass: "border-green-400 text-green-400",
  },
  {
    label: "Inactivo",
    value: 2,
    colorClass: "border-gray-500 text-gray-500",
  },
];

export const statusesLineBusiness = [
  {
    label: "Inactiva",
    value: 0,
    colorClass: "border-gray-500 text-gray-500",
  },
  {
    label: "Activa",
    value: 1,
    colorClass: "border-green-400 text-green-400",
  },
];


export const deductionsStatusSearch = [
  {
    label: "Todos",
    value: -1
  },
  {
    label: "Activa",
    value: 1,
  },
  {
    label: "Inactiva",
    value: 2,
  },
  {
    label: "Suspendida",
    value: 3,
  },
  {
    label: "Pagada",
    value: 4,
  },
];

export const deductionsStatus = [
  {
    label: "Activa",
    value: 1,
    colorClass: "border-green-400 text-green-400",
  },
  {
    label: "Inactiva",
    value: 2,
    colorClass: "border-gray-500 text-gray-500",
  },
  {
    label: "Suspendida",
    value: 3,
    colorClass: "border-orange-500 text-orange-500",
  },
  {
    label: "Pagada",
    value: 4,
    colorClass: "border-red-500 text-red-500",
  },
];

export const afpList = [
  {
    label: "AFP CRECER",
    value: "AFP CRECER",
  },
  {
    label: "AFP CONFÍA",
    value: "AFP CONFIA",
  },
  {
    label: "IPSFA",
    value: "IPSFA",
  },
];

export const document_types = [
  {
    value: 1,
    label: "Fisico",
  },
  {
    value: 2,
    label: "Digital",
  },
  {
    value: 3,
    label: "Ambos",
  },
];

export const employeesStatus = [
  {
    label: "Todos",
    value: 3,
  },
  {
    label: "Creado",
    value: 0,
  },
  {
    label: "Activo",
    value: 1,
  },
  {
    label: "Inactivo",
    value: 2,
  },
];

export const indemnizacionStatus = [
  {
    value: 0,
    label: "Proceso",
  },
  {
    value: 1,
    label: "Aplicada",
  },
];

export const basic_answers = [
  {
    value: 1,
    label: "SI",
  },
  {
    value: 0,
    label: "NO",
  },
];

export const periodicity = [
  {
    value: 15,
    label: 15,
  },
  {
    value: 30,
    label: 30,
  },
];

export const periodicitypayroll = [
  {
    value: 7,
    label: 7
  },
  {
    value: 15,
    label: 15,
  },
  {
    value: 30,
    label: 30,
  },
];


export const payrollStatus = [
  {
    label: "Creado",
    value: 0,
    colorClass: "border-blue-400 text-blue-400",
  },
  {
    label: "Activo",
    value: 1,
    colorClass: "border-green-400 text-green-400",
  },
  {
    label: "Inactivo",
    value: 2,
    colorClass: "border-gray-500 text-gray-500",
  },
  {
    label: "En Proceso",
    value: 3,
    colorClass: "border-orange-500 text-orange-500",
  },
  {
    label: "Finalizado",
    value: 4,
    colorClass: "border-red-500 text-red-500",
  },
];
export const payrollPeriodsStatus = [
  {
    label: "Creado",
    value: 0,
    colorClass: "border-blue-400 text-blue-400",
  },
  {
    label: "Inicializado",
    value: 1,
    colorClass: "border-green-400 text-green-400",
  },
  {
    label: "Modificado",
    value: 2,
    colorClass: "border-orange-500 text-orange-500",
  },
  {
    label: "Calculado",
    value: 3,
    colorClass: "border-teal-500 text-teal-500",
  },
  {
    label: "Aprobado",
    value: 4,
    colorClass: "border-indigo-500 text-indigo-500",
  },
  {
    label: "Aplicado",
    value: 5,
    colorClass: "border-red-400 text-red-400",
  },
];

export const paymentTypes = [
  {
    label: "Desc. Interno",
    value: 0,
  },
  {
    label: "Transferencia",
    value: 1,
  },
  {
    label: "Cheque",
    value: 2,
  },
];
export const calculationTypes = [
  {
    label: "Bono Fijo",
    value: 0,
  },
  {
    label: "Bono por días trabajados",
    value: 1,
  },
];
