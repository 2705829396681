import React from "react";
import cx from "classnames";
import Styled from "./styled";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";
import { ErrorMessage, getIn } from "formik";
import {
  size,
  isEqual,
  assign,
  omit,
  get,
  findIndex,
  map,
  isEmpty,
  has,
  filter,
  sortBy,
  upperCase,
} from "lodash";
import { IoIosArrowDown } from "react-icons/io";
import Skeleton from "react-loading-skeleton";

const animatedComponents = makeAnimated();

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected
      ? "#18353f"
      : state.isFocused
      ? "#18353f"
      : "#FFFFFF",
    "&:active": {
      // Overwrittes the different states of border
      background: "#18353f",
    },
    color: state.isSelected
      ? "#FFFFFF"
      : state.isFocused
      ? "#FFFFFF"
      : "#18353f",
  }),
};

const CaretDownIcon = () => {
  return (
    <IoIosArrowDown className="is-flex align-items-center justify-content-center c-pointer" />
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

export default (props) => {
  const {
    label,
    field,
    form,
    disabled = false,
    serchable = true,
    clearable = true,
    loadingOptions = false, // cuando carga la data dentro del select
    loading = false,
    isMultiple = false,
    isDestroyable = false,
    options = [],
    containerClass,
    afterOnChange = () => {},
  } = props;
  const { touched, errors, setFieldValue, setFieldTouched } = form;
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);

  return (
    <Styled
      className={cx("relative", {
        [containerClass]: containerClass,
      })}
    >
      <label
        htmlFor={field.name}
        className={cx("block text-gray-700 text-sm font-bold", {
          "mb-2": label,
        })}
      >
        {loading ? <Skeleton width="50%" /> : label}
      </label>

      {loading ? (
        <Skeleton height="3rem" />
      ) : (
        <Select
          inputId={field.name}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator,
            animatedComponents,
          }}
          className={cx(
            "select-input appearance-none border focus:border-blue-600 bg-transparent w-full text-gray-700 leading-tight focus:outline-none",
            {
              "border-red-700": touch && error,
              "border-green-300": touch && !error,
              "border-gray-400": !touch && !error,
            }
          )}
          styles={customStyles}
          {...field}
          {...props}
          closeMenuOnScroll
          noOptionsMessage={() => "No hay opciones"}
          isDisabled={disabled || loadingOptions || isEqual(size(options, 0))}
          isSearchable={serchable}
          isLoading={loadingOptions}
          isClearable={clearable}
          isMulti={isMultiple}
          options={options}
          onChange={(e, i) => {
            if (isMultiple && isDestroyable) {
              let index;
              let values;
              let currentOption;

              afterOnChange(e, setFieldValue, setFieldTouched);

              switch (get(i, "action", "")) {
                case "remove-value":
                  index = findIndex(get(field, "value", []), (item) =>
                    isEqual(
                      get(item, "value", ""),
                      get(i, "removedValue.value", "")
                    )
                  );
                  values = [...field.value];

                  values[index] = assign(
                    {},
                    { ...values[index] },
                    { delete: "1" }
                  );

                  return setFieldValue(field.name, values);
                case "clear":
                  return setFieldValue(
                    field.name,
                    map(get(field, "values", []), (item) =>
                      assign({}, { ...item, delete: "1" })
                    )
                  );
                case "select-option":
                  index = findIndex(
                    get(field, "value", []),
                    (item) => item.value === i.option.value
                  );

                  values = [...field.value];
                  currentOption = values[index];

                  if (
                    !isEmpty(currentOption) &&
                    has(currentOption, "delete") &&
                    isEqual(get(currentOption, "delete", ""), "1")
                  ) {
                    values[index] = assign({}, omit(values[index], "delete"));

                    return setFieldValue(field.name, values);
                  }

                  return setFieldValue(field.name, e);
                default:
                  return setFieldValue(field.name, e);
              }
            }

            setFieldValue(field.name, e);
            afterOnChange(e, setFieldValue, setFieldTouched);
          }}
          defaultValue={
            isMultiple || (isMultiple && isDestroyable)
              ? !isEmpty(get(field, "value", null)) &&
                sortBy(
                  filter(
                    get(field, "value", []),
                    (item) => !isEqual(get(item, "delete", ""), "1")
                  ),
                  (item) => upperCase(item.label)
                )
              : field.value
          }
          value={
            isMultiple || (isMultiple && isDestroyable)
              ? !isEmpty(get(field, "value", null)) &&
                sortBy(
                  filter(
                    get(field, "value", []),
                    (item) => !isEqual(get(item, "delete", ""), "1")
                  ),
                  (item) => upperCase(item.label)
                )
              : field.value
          }
          classNamePrefix="select-input"
        />
      )}

      <ErrorMessage
        name={field.name}
        render={() => {
          return touch && error ? (
            <p className="text-xs text-red-700 absolute">{error}</p>
          ) : null;
        }}
      />
    </Styled>
  );
};
