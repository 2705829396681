import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Field, Formik } from "formik";
import { IoMdArrowBack } from "react-icons/io";

//custom
import Styled from "./styled";
import Input from "../../../components/Form/InputText";
import Button from "../../../components/Buttons/Button";

//validators
import Validators from "./Validators";
// import UrbanoLogo from "../../../components/logos/urbano";
// import UrbanoBusinessLines from "../../../components/logos/urbanoBusinessLines";

// actions
// import { forgotPasswordRequest } from "../../../store/auth/actions/postForgotPassword";

const ForgotPasswordPage = ({ isLogged, isLoading, dispatch, history }) => {
  useEffect(() => {
    if (isLogged) {
      return history.replace("/");
    }
  }, [isLogged, history]);

  const [showMessage, setShowMessage] = useState(false);

  const onSubmit = async (values, setErrors) => {
    // const { email } = values;

    // let formData = {
    //   email,
    // };

    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
      return history.push("/log-in");
    }, 3000);

    // return await new Promise((resolve, reject) => {
    //   dispatch(
    //     forgotPasswordRequest(formData, (error, dataResponse) => {
    //       console.log("onSubmit -> error, dataResponse", error, dataResponse);
    //       if (error) {
    //         setErrors({
    //           user: "Correo inválido",
    //         });
    //         return reject(error);
    //       }

    //       resolve();
    //       return history.push("/");

    //     })
    //   );
    // });
  };

  return (
    <Styled className="h-screen w-screen grid grid-cols-4 animate__animated animate__fadeIn">
      <div className="col-span-2 bg-gray-700 login-right-side flex items-center justify-center">
        {/* <UrbanoBusinessLines className="w-3/4" /> */}
      </div>
      <div className="col-span-2 relative">
        {/* <div className="absolute top-0 left-0 flex items-center"> */}
        <Link
          to="/log-in"
          className="absolute top-0 left-0 flex items-center hover:opacity-75"
        >
          <IoMdArrowBack size="2rem" />
          <p className="ml-2 font-bold">Regresar</p>
        </Link>
        {/* </div> */}
        <Formik
          initialValues={{
            email: "",
          }}
          enableReinitialize
          validationSchema={Validators}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            onSubmit(values, setErrors);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, resetForm, values, setFieldValue }) => {
            return (
              <Form className="w-full h-full flex flex-col items-center justify-center flex-wrap">
                <div className="text-center">
                  {/* <UrbanoLogo className="mb-5 mx-auto" /> */}
                  <h3 className="mb-10 w-3/4 mx-auto">
                    Ingresa tu correo para enviarte una contraseña temporal
                  </h3>
                </div>
                <div className="w-3/5">
                  <Field
                    name="email"
                    label="Correo"
                    placeholder="Digite su correo"
                    component={Input}
                    containerClass="mb-8"
                  />
                  <Button
                    type="submit"
                    isLoading={isSubmitting || isLoading}
                    text="Enviar"
                  />
                  {showMessage && (
                    <p className="text-center py-3 text-green-500 font-bold">
                      ¡Correo enviado!
                    </p>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Styled>
  );
};

export default ForgotPasswordPage;
