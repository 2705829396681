import { lazy } from "react";
import { connect } from "react-redux";
// import { getAllPersonnelActions } from "../../../hooks/PersonnelActions/getAllPersonnelActions";

const CrearPersonalAction = lazy(() => import("./Create"));

const mapStateToProps = (state) => {
  return({
  // dataPersonnelActions: state.personnelActions.personnelActions.data,
  // isLoading: state.personnelActions.personnelActions.loading,
  // isError: state.personnelActions.personnelActions.error,
  // pagination: state.personnelActions.personnelActions.pagination,
})};

const mapDispatchToProps = dispatch => ({
  // getAllPersonnelActions: (formData) => dispatch(getAllPersonnelActions(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CrearPersonalAction);
