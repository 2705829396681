/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createRef, useEffect } from "react";
import { MdMenu } from "react-icons/md";
import { FaCaretDown } from "react-icons/fa";
import { map, get, find, isEmpty, trim } from "lodash";
import { useSelector } from "react-redux"
import * as storage from "localforage";

// custom
import Styled from "./styled";
import ULogoWhite from "../../logos/uLogoWhite";
import MenuItem from "../MenuItem/MenuItem";
import SideBar from "../SideBar";
import CloseSesionModal from "../../Modals/CloseSesion";
import ChooseCompanyModal from "../../Modals/ChooseCompany";
import SideBarMenu from "../../../utils/sideBarMenu";
import ChooseCompanyQuestion from "../../Modals/ChooseCompanyQuestion";
import RefreshTokenPage from "../../../modules/Auth/Refresh-Token";

import { getCompaniesRequest } from "../../../store/administration/actions/companies/getCompanies";
import {
  setUserCompanyRequest,
  setUserCompanySuccess,
  setUserCompanyError,
  getCompaniesPermited
} from "../../../store/auth/actions/setUserCompany";

export default function TemporaryDrawer({
  isLogged,
  isRefreshingToken,
  children,
  history,
  location,
  dispatch,
  isLoadingCompanies,
  companies,
  currentUser,
}) {
  const sideBarRef = createRef();
  const { name, user_id } = useSelector(state => state.authentication);
  const modules = useSelector(state => state.authentication.modules)

  const [modal, setModal] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);
  const [companyModal, setCompanyModal] = useState(false);
  const [companyQuestionModal, setCompanyQuestionModal] = useState(false);
  const [companySelected, setCompanySelected] = useState(null);
  const [sideBar, setSideBar] = useState([])

  useEffect(() => {
    /*
      - elimina del menú los menú  a los que no tiene acceso
      Nota: acá solo se evalúa si aparece o nó en el  menú.
      si quiere conocer si tiene o no permiso a la ruta debe dirigirse a /src/routes/PrivateRoute
    */
    const primaryMenu = []
    try {


      const idCompany = currentUser?.currentCompany?.id;
      const validModules = modules?.find((e) => e?.id === idCompany)

      for (const module of get(validModules, "module", [])) {
        if (isEmpty(module.privileges)) {
          // evalua los menú que no tienen privegios designados y da acceso a todos los submodulos
          const currentMenu = SideBarMenu.find((menu) => menu.name === module.name)
          primaryMenu.push(currentMenu)

        } else {
          // evalua los menú que si tienen privilegios designados y da acceso solo a los designados
          const currentMenu = SideBarMenu.find((menu) => menu.name === module.name)
          const secondaryMenu = []
          for (const smenu of get(currentMenu, "submodules", [])) {
            for (const smodule of get(module, "privileges", [])) {
              if (smenu.path.includes(smodule.url)) {
                secondaryMenu.push(smenu)
              }
            }
          }
          currentMenu.submodules = secondaryMenu
          primaryMenu.push(currentMenu)
        }
      }
      setSideBar(primaryMenu)
    } catch (error) {
      setSideBar(primaryMenu)
    }

  }, [])

  useEffect(() => {
    if (currentUser?.currentCompany?.id) {
      if (JSON.parse(localStorage.getItem("idC")) !== currentUser?.currentCompany?.id) {
        localStorage.setItem("idC", currentUser?.currentCompany?.id)
        if (location.pathname !== "/home") {
          setTimeout(() => {
            window.location.replace("/home")
          }, 1000);
        }
      }
    }
  }, [localStorage.getItem("idC")])

  const onLogout = () => history.push("/log-out");

  const handleOpenCompanyModal = async () => {
    setCompanyModal(true);
    if (companies.length === 0) {
      return await new Promise((resolve, reject) => {
        dispatch(
          getCompaniesRequest({}, (error, response) => {
            if (error) {
              return reject(error);
            }

            return resolve();
          })
        );
      });
    }
  };

  const onChangeCompany = async (values) => {
    const {
      company: { value },
    } = values;

    const currentUserCompany = Number(get(currentUser, "currentCompany.id"));
    const currentCompanySelected = Number(value);

    if (currentCompanySelected === currentUserCompany) {
      return setCompanyModal(false);
    } else {
      setCompanySelected(currentCompanySelected);
      setCompanyQuestionModal(true);
    }
  };

  const handleCompanyQuestionModal = async () => {
    try {

      setCompanyModal(false);
      setCompanyQuestionModal(false);
      const currentCompany = find(companies, ["id", companySelected]);

      dispatch(setUserCompanyRequest())
      dispatch(setUserCompanySuccess({ currentCompany: currentCompany }))

      await storage.setItem("companyId", get(currentCompany, "id"));
      await storage.setItem("userId", user_id);

      // return await new Promise((resolve, reject) => {
      //   console.log("se ejecuto la promesa")
      //   dispatch(
      //     setUserCompanyRequest(currentCompany, (error, response) => {
      //       if (error) {
      //         return reject(error);
      //       }
      //       console.log(response)
      //       // return history.push("/");
      //       // return window.location.replace("/home")
      //       resolve()
      //     })
      //   );
      // });
    } catch (error) {
      console.log(error)
      dispatch(setUserCompanyRequest(error))
    }
  };


  if (!isLogged) return null;

  if (isRefreshingToken) {
    return <RefreshTokenPage />;
  }

  const proccessName = (nameUser) => {
    let userName = {
      firstName: nameUser,
      capitalLetter: ""
    }

    try {
      const index = nameUser.indexOf(" ")
      userName.firstName = nameUser.substr(0, index)
    } catch (error) { }
    try {
      userName.capitalLetter = nameUser.substr(0, 1)
    } catch (error) { }


    return userName;
  }

  return (
    <Styled className="animate__animated animate__fadeIn">
      <SideBar ref={sideBarRef}>
        <ULogoWhite className="w-2/4 mx-auto mb-2" />
        {map(sideBar, (option, index) => {
          return (
            <MenuItem
              key={index}
              icon={option.icon}
              text={option.name}
              submodules={get(option, "submodules", [])}
              active={option.name === optionSelected}
              onClick={() => setOptionSelected(option.name)}
              onClose={() => {
                sideBarRef.current.handleIsOpen(false);
                setOptionSelected(null);
              }}
            />
          );
        })}
      </SideBar>
      <div className="app-bar shadow flex items-center justify-between">
        <div className="flex items-center">
          <MdMenu
            className="mx-5 cursor-pointer hover:opacity-50"
            onClick={() => {
              setOptionSelected(null);
              sideBarRef.current.handleIsOpen(true);
            }}
            size="2rem"
          />
          <h2>WEB -</h2>
          {/* <button className="ml-3 bg-transparent hover:bg-gray-900 text-gray-900 font-semibold hover:text-white py-2 px-4 border border-gray-900 hover:border-transparent rounded focus:outline-none">
                {get(currentUser, "currentCompany.name", "")}
              </button> */}
          <button
            className="ml-1 bg-transparent text-gray-900 focus:outline-none hover:opacity-50"
            onClick={handleOpenCompanyModal}
          >
            <h2>{get(currentUser, "currentCompany.name", "")}</h2>
          </button>
        </div>
        <div
          onClick={() => setModal(true)}
          className="flex items-center mr-5 hover:opacity-75 cursor-pointer"
        >
          <p>
            <b>{proccessName(name).firstName}</b>
          </p>
          <div className="w-10 h-10 bg-red-700 rounded-full flex items-center justify-center text-white ml-3">
            <p>
              <b>{proccessName(name).capitalLetter}</b>
            </p>
          </div>
          <FaCaretDown size="1.2rem" className="text-red-700 ml-3" />
        </div>
      </div>
      <div className="layout-content bg-gray-200">{children}</div>
      <CloseSesionModal
        isActive={modal}
        onAccept={onLogout}
        onCancel={() => setModal(false)}
      />
      <ChooseCompanyModal
        isActive={companyModal}
        onCancel={() => setCompanyModal(false)}
        initialValues={{
          company: {
            value: get(currentUser, "currentCompany.id"),
            label: get(currentUser, "currentCompany.name"),
          },
        }}
        companies={map(companies, (company) => ({
          value: company.id,
          label: company.name,
        }))}
        loadingOptions={isLoadingCompanies}
        onSubmit={onChangeCompany}
      />
      <ChooseCompanyQuestion
        isActive={companyQuestionModal}
        onAccept={handleCompanyQuestionModal}
        onCancel={() => setCompanyQuestionModal(false)}
      />
    </Styled>
  );
}
