import React from "react";
import { Route, Switch } from "react-router-dom";

import AFP from "../../../modules/Legal/AFP";

export default () => {
  return (
    <Switch>
      <Route exact path="/legal/afp" component={AFP} />
    </Switch>
  );
};