import React from "react";
import { Route, Switch } from "react-router-dom";

import ISSS from "../../../modules/Legal/ISSS";

export default () => {
  return (
    <Switch>
      <Route exact path="/legal/isss" component={ISSS} />
    </Switch>
  );
};