import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import cx from "classnames";
import mergeRefs from "react-merge-refs";

import Styled from "./styled";

export default forwardRef(({ children }, ref) => {
  const node = useRef();
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      setOpen(true);
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClick);
    } else {
      document.removeEventListener("mousedown", handleClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [open]);

  useImperativeHandle(ref, () => ({
    handleIsOpen(value) {
      setOpen(value);
    },
  }));
  return (
    <Styled
      className={cx("sidebar h-screen bg-white absolute z-10 shadow-xl py-1", {
        active: open,
      })}
      ref={mergeRefs([node, ref])}
    >
      <div className="relative w-full h-full">{children}</div>
    </Styled>
  );
});
