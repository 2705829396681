import styled from "styled-components";

import { tuna } from "../../../../utils/colors";

const Styled = styled.div`
  background-color: ${tuna};
  left: 0;
  top: 0;
  width: 14rem;
  transform: translate(-16rem);
  transition: transform 0.3s;
  &.active {
    transform: translate(0);
  }
  .sidebar-close-icon {
    /* transform: rotate(0); */
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    -ms-transition: -ms-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    right: -3.5rem;

    &:hover {
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }
  }
`;

export default Styled;
