import styled from "styled-components";

import { tuna, doveGray } from "../../../../utils/colors";

const Styled = styled.div`
  width: 100%;
  .option-menu {
    position: relative;
    .sub-menu {
      position: absolute;
      background: ${doveGray};
      top: 0;
      right: -14rem;
      display: none;
      .sub-menu-option {
        &:hover {
          background-color: ${tuna};
        }
      }
    }
  }
  .option-menu {
    .arrow {
      position: absolute;
      right: 0;
      top: 0;
      width: 0;
      height: 0;
      border-top: 1.3rem solid transparent;
      border-bottom: 1.3rem solid transparent;
      border-right: 1.3rem solid ${doveGray};
      display: none;
    }
    &:hover {
      background: rgba(247, 248, 252, 0.1);
      & > .sub-menu,
      .arrow {
        display: block;
      }
    }
  }
`;

export default Styled;
