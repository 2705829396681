import { put, takeLatest, select, call, delay } from "redux-saga/effects";
import { get, find, isEmpty } from "lodash";
import * as storage from "localforage";
import { getCompaniesPermited } from "../actions/setUserCompany"

//* Login
import {
  // action types
  LOGIN_REQUEST,
  LOGIN_ERROR,
  // requests
  getLoginRequest,
  // actions
  loginSuccess,
  loginError,
} from "../actions/login";

//* Current User
import {
  // action types
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_ERROR,
  // requests
  currentUserRequest,
  // actions
  getCurrentUserSuccess,
  getCurrentUserError,
} from "../actions/getCurrentUser";

//* Set User Company
import {
  // action types
  SET_USER_COMPANY_REQUEST,
  // actions
  setUserCompanySuccess,
  setUserCompanyError,
} from "../actions/setUserCompany";

//* refresh token
import {
  // action types
  REFRESH_TOKEN_REQUEST,
  // requests
  postRefreshTokenRequest,
  // actions
  refreshTokenRequest,
  refreshTokenSuccess,
  refreshTokenError,
} from "../actions/refreshToken";

//* forgot password
import {
  // action types
  POST_FORGOT_PASSWORD_REQUEST,
  // requests
  postforgotPasswordRequest,
  // actions
  forgotPasswordSuccess,
  forgotPasswordError,
} from "../actions/postForgotPassword";

//* logout
// actions
// import { logout } from "../actions/logout";

function* login(action) {
  try {
    const data = yield call(getLoginRequest, action.payload);
    yield storage.setItem("token", get(data, "data.access_token", ""));

    // obtener id company (ya que para este momento no existe aún)
    const getCompany = find(get(data, "data.modules", []), (company) => !isEmpty(company.module))
    const getIdCompany = get(getCompany, "id", 1)

    // obtener la empresa a la que tiene derecho este fulano.
    const resp = yield getCompaniesPermited(get(data, "data.user_id", ""), get(data, "data.access_token", ""), getIdCompany)
    const companyAccessList = get(resp, "data.data", [])
    const currentCompany = find(companyAccessList, (company) => !!(company.id))

    yield storage.setItem("companyId", 1);

    yield put(loginSuccess(data.data));
    yield put(getCurrentUserSuccess({ currentCompany }))

    action.callback && action.callback(null, data.data);
  } catch (e) {
    yield put(loginError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* refreshTokenSaga(action) {
  const { authentication } = yield select();
  const { refresh_token } = authentication;

  try {
    const data = yield call(postRefreshTokenRequest, { refresh_token });

    yield storage.setItem("token", data.data.access_token);
    yield put(loginSuccess(data.data));
    // yield put(getCurrentEmployeeAction());
    yield put(refreshTokenSuccess());

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(refreshTokenError(e.response));
    // yield put(logout());

    global.location.href = "/log-out";

    action.callback && action.callback(e.response);
  }
}

function* getCurrentUser(action) {
  try {
    const data = yield call(currentUserRequest);
    yield put(getCurrentUserSuccess(data.data));
    action.callback && action.callback(null, data.data);
  } catch (e) {
    yield put(getCurrentUserError(e));
    action.callback && action.callback(e);
  }
}

function* setUserCompany(action) {
  try {
    const newCompany = get(action, "payload");
    yield storage.setItem("companyId", get(newCompany, "id"));
    yield put(setUserCompanySuccess({ currentCompany: newCompany }));
    yield delay(700);
    yield action.callback(null, newCompany);
  } catch (e) {
    yield put(setUserCompanyError(e));
    action.callback && action.callback(e);
  }
}

function* postForgotPassword(action) {
  try {
    const data = yield call(postforgotPasswordRequest, action.payload);

    yield storage.setItem("token", data.data.access_token);
    yield put(forgotPasswordSuccess(data.data));

    action.callback && action.callback(null, data.data);
  } catch (e) {
    yield put(forgotPasswordError(e.response));

    action.callback && action.callback(e.response);
  }
}

function* catchError(action) {
  try {
    const status = action.payload ? action.payload.status : 0;

    if (status === 401) {
      yield put(refreshTokenRequest());
    }
  } catch (error) {
    // yield put(logout());

    global.location.href = "/log-out";
  }
}

function* loginSaga() {
  yield takeLatest(LOGIN_REQUEST, login);
  yield takeLatest(LOGIN_ERROR, catchError);
  yield takeLatest(GET_CURRENT_USER_REQUEST, getCurrentUser);
  yield takeLatest(GET_CURRENT_USER_ERROR, catchError);
  yield takeLatest(SET_USER_COMPANY_REQUEST, setUserCompany);
  yield takeLatest(REFRESH_TOKEN_REQUEST, refreshTokenSaga);
  yield takeLatest(POST_FORGOT_PASSWORD_REQUEST, postForgotPassword);
}

export default loginSaga;
