import React from "react";

import { ReactComponent } from "../../../assets/images/urbano-logo-white.svg";
import { white } from "../../../utils/colors";

const ULogoWhite = ({ fillColor = white, ...props }) => {
  return <ReactComponent fill={fillColor} {...props} />;
};

export default ULogoWhite;
