//All Employees
import {
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_ERROR,
} from "../../actions/employees/getEmployees";

//Employee
import {
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_ERROR,
} from "../../actions/employees/getEmployee";

//Employee NextCode
import {
  GET_NEXT_CODE_REQUEST,
  GET_NEXT_CODE_SUCCESS,
  GET_NEXT_CODE_ERROR,
} from "../../actions/employees/getNextCode";

//Employee Unsuscribe
import {
  PUT_UNSUSCRIBE_REQUEST,
  PUT_UNSUSCRIBE_SUCCESS,
  PUT_UNSUSCRIBE_ERROR,
} from "../../actions/employees/putUnsuscribe";

//Personal Actions Type
import {
  GET_PERSONAL_ACTIONS_TYPES_REQUEST,
  GET_PERSONAL_ACTIONS_TYPES_SUCCESS,
  GET_PERSONAL_ACTIONS_TYPES_ERROR,
} from "../../actions/employees/getPersonalActionsType";

//Update Employee
import {
  PUT_EMPLOYEE_REQUEST,
  PUT_EMPLOYEE_SUCCESS,
  PUT_EMPLOYEE_ERROR,
} from "../../actions/employees/putEmployee";

//Create Employee
import {
  POST_EMPLOYEE_REQUEST,
  POST_EMPLOYEE_SUCCESS,
  POST_EMPLOYEE_ERROR,
} from "../../actions/employees/postEmployee";

//Logout
import { LOGOUT } from "../../../auth/actions/logout";

const INITIAL_STATE = {
  loading: false,
  loadingAction: false,
  error: false,
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  detail: {},
  nextCode: {
    loading: false,
    error: false,
    data: {},
  },
  personalActionsType: {
    loading: false,
    error: false,
    data: [],
  },
  unsuscribe: {
    loading: false,
    error: false,
    data: {},
    success: false,
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYEES_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GET_EMPLOYEES_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    // --------------------
    case GET_NEXT_CODE_REQUEST:
      return {
        ...state,
        nextCode: {
          data: {},
          error: false,
          loading: true,
        }
      };
    case GET_NEXT_CODE_SUCCESS:
      return {
        ...state,
        nextCode: {
          data: action.payload,
          error: false,
          loading: false,
        }
      };
    case GET_NEXT_CODE_ERROR:
      return {
        ...state,
        nextCode: {
          data: {},
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // --------------------
    // --------------------
    case PUT_UNSUSCRIBE_REQUEST:
      return {
        ...state,
        unsuscribe: {
          loading: true,
          error: false,
          data: {},
          success: false,
        }
      };
    case PUT_UNSUSCRIBE_SUCCESS:
      return {
        ...state,
        unsuscribe: {
          loading: false,
          error: false,
          data: action.payload,
          success: true,
        }
      };
    case PUT_UNSUSCRIBE_ERROR:
      return {
        ...state,
        unsuscribe: {
          loading: false,
          data: {},
          success: false,
          error: action.payload || action.payload.status || 401,
        }
      };
    // --------------------
    case GET_PERSONAL_ACTIONS_TYPES_REQUEST:
      return {
        ...state,
        personalActionsType: {
          data: [],
          error: false,
          loading: true,
        }
      };
    case GET_PERSONAL_ACTIONS_TYPES_SUCCESS:
      return {
        ...state,
        personalActionsType: {
          data: action.payload.data,
          error: false,
          loading: false,
        }
      };
    case GET_PERSONAL_ACTIONS_TYPES_ERROR:
      return {
        ...state,
        personalActionsType: {
          data: [],
          error: action.payload || action.payload.status || 401,
          loading: false,
        }
      };
    // --------------------
    case GET_EMPLOYEE_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: false,
        loading: false,
      };
    case GET_EMPLOYEE_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case PUT_EMPLOYEE_REQUEST:
      return {
        ...state,
        error: false,
        loadingAction: true,
      };
    case PUT_EMPLOYEE_SUCCESS:
      return {
        ...state,
        error: false,
        loadingAction: false,
      };
    case PUT_EMPLOYEE_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loadingAction: false,
      };
    case POST_EMPLOYEE_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_EMPLOYEE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case POST_EMPLOYEE_ERROR:
      return {
        ...state,
        error: action.payload || action.payload.status || 401,
        loading: false,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
