import React from "react";
import { Link } from "react-router-dom";
import { Form, Field, Formik } from "formik";

//custom
import Styled from "./styled";
import Input from "../../../components/Form/InputText";
import Button from "../../../components/Buttons/Button";
// import UrbanoLogo from "../../../components/logos/urbano";
// import UrbanoBusinessLines from "../../../components/logos/urbanoBusinessLines";

//validators
import Validators from "./Validators";

// actions
import { loginRequest } from "../../../store/auth/actions/login";

const SignInPage = ({ isLogged, isLoading, dispatch, history }) => {
  const onSubmit = async (values, setErrors) => {
    const { password, user: username } = values;

    let formData = {
      username,
      password,
    };

    return await new Promise((resolve, reject) => {
      dispatch(
        loginRequest(formData, (error, dataResponse) => {
          if (error) {
            setErrors({
              user: "Credenciales inválidas",
            });
            return reject(error);
          }

          resolve();
          const lastPath = localStorage.getItem("lastPath") || "/";

          return history.replace(lastPath);

          //   dispatch(
          //   getCurrentEmployeeRequest(getCurrentEmployeeError => {
          //     if (!getCurrentEmployeeError) {
          //       resolve();

          //       return history.push('/unbilled');
          //     }

          //     return reject(getCurrentEmployeeError);
          //   })
          // );
        })
      );
    });
  };

  return (
    <Styled className="h-screen w-screen grid grid-cols-4 animate__animated animate__fadeIn">
      <div className="col-span-2">
        <Formik
          initialValues={{
            user: "",
            password: "",
          }}
          enableReinitialize
          validationSchema={Validators}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            onSubmit(values, setErrors);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, resetForm, values, setFieldValue }) => {
            return (
              <Form className="w-full h-full flex flex-col items-center justify-center flex-wrap">
                <div className="text-center">
                  {/* <UrbanoLogo className="mb-5" /> */}
                  <h2 className="mb-10 font-bold uppercase">WEB</h2>
                </div>
                <div className="w-3/5">
                  <Field
                    name="user"
                    label="Correo"
                    placeholder="Digite usuario o correo"
                    component={Input}
                    containerClass="mb-8"
                  />
                  <Field
                    type="password"
                    name="password"
                    label="Contraseña"
                    placeholder="Digite Contraseña"
                    component={Input}
                    containerClass="mb-0"
                  />
                  <div className="w-full flex justify-end mb-8 mt-3">
                    <Link to="/forgot-password" className="text-gray-600">
                      ¿Olvido su contraseña?
                    </Link>
                  </div>
                  <Button
                    type="submit"
                    isLoading={isSubmitting || isLoading}
                    text="Ingresar"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="col-span-2 bg-gray-700 login-right-side flex items-center justify-center">
        {/* <UrbanoBusinessLines className="w-3/4" /> */}
      </div>
    </Styled>
  );
};

export default SignInPage;
