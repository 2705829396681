import React from "react";
import { MdStreetview } from "react-icons/md";
const iconSize = "1.2rem";
const iconColor = "#ffffff";

/*
  NOTA: Sí agrega más items al menú principal y aunque se agregen a modulos en bakend.
  es necesario que tambien incluya su nuevo menú a rutesPrivates
*/

export const routesPrivates = [
  { module_id: 1, path: "/administration" },
  { module_id: 2, path: "/employees" },
  { module_id: 3, path: "/payroll" },
  { module_id: 4, path: "/deductions" },
  { module_id: 5, path: "/benefits" },
  { module_id: 6, path: "/legal" },
  { module_id: 7, path: "/personnel-actions" },
  { module_id: 8, path: "/vacations" },
  { module_id: 9, path: "/security" },
]

export default [
  {
    name: "Administración",
    path: "/administration",
    icon: <MdStreetview size={iconSize} color={iconColor} />,
    submodules: [
      {
        name: "Países",
        path: "/administration/countries",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Empresas",
        path: "/administration/companies",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Roles",
        path: "/administration/roles",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Conceptos Categorías",
        path: "/administration/concept-categories",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Conceptos Tipos",
        path: "/administration/concept-types",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Areas/Sub Areas",
        path: "/administration/areas",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Ubicaciones",
        path: "/administration/ubications",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Puestos",
        path: "/administration/positions",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Asignaciones",
        path: "/administration/assignments",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Requisitos",
        path: "/administration/requirements",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Centros de costos",
        path: "/administration/costcenters",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Documentos",
        path: "/administration/documents",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Entidades Financieras",
        path: "/administration/financial-entities",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Linea de Negocios",
        path: "/administration/line-business",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Parametros de Sistema",
        path: "/administration/settings",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
    ],
  },
  {
    name: "Empleados",
    path: "/employees",
    icon: <MdStreetview size={iconSize} color={iconColor} />,
    submodules: [
      {
        name: "Empleados",
        path: "/employees/employees",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Reportes",
        path: "/employees/reports",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
    ],
  },
  {
    name: "Nómina",
    path: "/payroll",
    icon: <MdStreetview size={iconSize} color={iconColor} />,
    submodules: [
      {
        name: "Admin. Nóminas",
        path: "/payroll/administration",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Nómina-Conceptos",
        path: "/payroll/concepts",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Historial",
        path: "/payroll/history",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Reportes",
        path: "/payroll/reports",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Boleta de pago",
        path: "/payroll/voucher",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
    ],
  },
  {
    name: "Deducciones",
    path: "/deductions",
    icon: <MdStreetview size={iconSize} color={iconColor} />,
    submodules: [
      {
        name: "Admin. Deducciones",
        path: "/deductions/administration",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Histórico",
        path: "/deductions/historical",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Cuadre financiero",
        path: "/deductions/financial-square",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Reportes",
        path: "/deductions/reports",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
    ],
  },
  {
    name: "Beneficios",
    path: "/benefits",
    icon: <MdStreetview size={iconSize} color={iconColor} />,
    submodules: [
      {
        name: "Admin. Beneficios",
        path: "/benefits/administration",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Histórico",
        path: "/benefits/historical",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Reportes",
        path: "/benefits/reports",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
    ],
  },
  {
    name: "Legales",
    path: "/legal",
    icon: <MdStreetview size={iconSize} color={iconColor} />,
    submodules: [
      {
        name: "ISSS",
        path: "/legal/isss",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "AFP",
        path: "/legal/afp",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "ISR Mensual",
        path: "/legal/isr",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
    ],
  },
  {
    name: "Acciones de personal",
    path: "/personnel-actions",
    icon: <MdStreetview size={iconSize} color={iconColor} />,
    submodules: [
      {
        name: "Administración",
        path: "/personnel-actions/administration",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Histórico",
        path: "/personnel-actions/histories",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Creación",
        path: "/personnel-actions/create",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Aprobación",
        path: "/personnel-actions/approval",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
    ],
  },
  {
    name: "Vacaciones",
    path: "/vacations",
    icon: <MdStreetview size={iconSize} color={iconColor} />,
    submodules: [
      {
        name: "Periodo actual",
        path: "/vacations/current-period",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
      {
        name: "Goce de vacaciones",
        path: "/vacations/enjoy-vacations",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
    ],
  },
  {
    name: "Seguridad",
    path: "/security",
    icon: <MdStreetview size={iconSize} color={iconColor} />,
    submodules: [
      {
        name: "Usuarios",
        path: "/security/users",
        icon: <MdStreetview size={iconSize} color={iconColor} />,
      },
    ],
  },
];
