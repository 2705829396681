import styled from "styled-components";

import { persianRed, catskillWhite } from "../../../../utils/colors";

const Styled = styled.div`
  .select-input {
    /* height: 100%; */
    border-radius: 0.3rem;

    .select-input__control {
      height: 100%;
      border: 0;
      padding-top: 0.36rem;
      padding-bottom: 0.36rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .select-input__control--is-disabled {
      background: ${catskillWhite};
      cursor: not-allowed;
    }
    .select-input__control--is-focused {
      box-shadow: none;
      /* border: 1px solid ${persianRed}; */
    }
    .select-input__value-container {
      overflow-y: auto;
      max-height: 4rem;
      padding-left: 0;
      /* color: ${persianRed}; */
    }

    .select-input__indicator{
      padding:0px 8px;
    }
    .select-input__placeholder {
      color: rgba(74, 85, 104, 0.3);
      font-size: 16px;
    }

    .select-input__input {
      font-size: 14px;
    }

    .select-input__menu {
      margin-top: 3px;
    }

    .select-input__option {
      font-size: 14px;
      cursor: pointer;
    }
  }
`;

export default Styled;
