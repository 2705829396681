import React from "react";
import AppRouter from "./routers/AppRouter";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#373841',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#49bb78',
      contrastText: '#FFF',
    },
    error: {
      main: '#c53131',
    },
  },
});

const App = () => (
  <ThemeProvider theme={theme}>
    <AppRouter />
  </ThemeProvider>
);

export default App;
