import React from "react";
import { Link } from "react-router-dom";
import { MdStreetview } from "react-icons/md";
import { map, get, sortBy } from "lodash";

import Styled from "./styled";

const MenuItem = ({
  icon = <MdStreetview size="2rem" color="#ffffff" />,
  text,
  submodules = [],
  active,
  onClick = () => {},
  onClose = () => {},
}) => {
  return (
    <Styled>
      <div
        onClick={onClick}
        className="option-menu w-full flex items-center cursor-pointer py-3 px-5"
      >
        {icon}
        <p className="ml-3 text-white text-sm">
          <b>{text}</b>
        </p>
        <span className="arrow"></span>
        <div className="w-full sub-menu">
          {map(sortBy(submodules, "name"), (submodule, index) => {
            return (
              <Link
                to={submodule.path}
                key={index}
                className="sub-menu-option w-full flex items-center cursor-pointer py-2 px-3"
                onClick={onClose}
              >
                <p className="text-white">
                  <b>{get(submodule, "name", "--")}</b>
                </p>
              </Link>
            );
          })}
        </div>
      </div>
    </Styled>
  );
};

export default MenuItem;
