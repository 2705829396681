import styled from "styled-components";

import { persianRed, white } from "../../../../utils/colors";

const Styled = styled.button`
  border: 1px solid ${persianRed};
  &:hover {
    background: ${persianRed};
    color: ${white};
  }
  &:active {
    opacity: 0.8;
  }
`;

export default Styled;
