import React from "react";
import { ErrorMessage, getIn } from "formik";
import Skeleton from "react-loading-skeleton";
import cx from "classnames";

import Styled from "./styled";

export default ({
  field = { name: "" },
  form = { touched: false, errors: false },
  iconRight = null,
  isTextArea = false,
  rows = 3,
  containerClass,
  loading,
  ...props
}) => {
  const { touched, errors } = form;
  const { label } = props;
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);

  return (
    <Styled
      className={cx("relative", {
        [containerClass]: containerClass,
      })}
    >
      <label
        htmlFor={field.name}
        className="block text-gray-700 text-sm font-bold mb-2"
      >
        {loading ? <Skeleton width="50%" /> : label}
      </label>
      {loading ? (
        <Skeleton height="3rem" />
      ) : (
        <input
          autoComplete="off"
          type="text"
          id={field.name}
          className={cx(
            "appearance-none border focus:border-black bg-transparent w-full text-gray-700 py-3 px-2 leading-tight focus:outline-none",
            {
              "border-red-700": touch && error,
              "border-green-300": touch && !error,
              "border-gray-400": !touch && !error,
            }
          )}
          {...field}
          {...props}
        />
      )}
      <ErrorMessage
        name={field.name}
        render={() => {
          return touch && error ? (
            <p className="text-xs text-red-700 absolute">{error}</p>
          ) : null;
        }}
      />
    </Styled>
  );
};
