import styled from "styled-components";

import { tuna, white } from "../../../../utils/colors";

const appBarHeight = "4.375rem";

const Styled = styled.div`
  .app-bar {
    height: ${appBarHeight};
    border-bottom: 2px solid #f7fafc;
  }
  .app-drawer {
    background: ${tuna};
    color: ${white};
  }
  .layout-content {
    height: calc(100vh - ${appBarHeight});
    padding: 1rem;
  }
  .layout-content-custom {
    height: auto;
  }
`;

export default Styled;
