import React from "react";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";

//Customs
import PrivateRoute from "../PrivateRoute";

//Selectors
import { isLogged } from "../../store/auth/selectors";

//* ROUTES *//
import ISSS from "./ISSS";
import AFP from "./AFP";
import ISRMensual from "./ISRMensual";

const LegalRoutes = ({ isLogged }) => {
  return (
    <Switch>
      <PrivateRoute
        path="/legal/isss"
        component={ISSS}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/legal/afp"
        component={AFP}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/legal/isr"
        component={ISRMensual}
        isAuthenticated={isLogged}
      />
      <Redirect to="/" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isLogged: isLogged(state),
});

export default connect(mapStateToProps)(LegalRoutes);
