import { combineReducers } from "redux";

import afp from "./afp";
import isss from "./isss";
import isr from "./isr";

export default combineReducers({
  afp,
  isss,
  isr,
});
